import React from 'react';
import david from '../../assets/images/team/david.png';
import ashley from '../../assets/images/team/ashley.png';
import angela from '../../assets/images/team/angela.png';
import jacqueline from '../../assets/images/team/jacqueline.png';
import juliann from '../../assets/images/team/juliann.png';
import claudia from '../../assets/images/team/claudia.png';
import './style.scss';

const TEAM = [
  {
    name: 'Dr David de Beer (Owner)',
    title: 'Mb,ChB FRNZCGP',
    picture: david,
    description: 'I have always been interested in how humans work. As much as general practice has been a job and an important service to the community, it has been an ongoing learning experience for me. I’ve set my view on gaining more knowledge in “point of care ultrasound” and expanding surgical skills. I studied at the University of the Orange Free State (South Africa) and initially worked in Namibia, then a stint in Australia and back to South Africa and Lesotho. The New Zealand landscape and people attracted me in 1998 and I worked in some rural areas before coming to Cambridge. It has been a great place for our blended family and we enjoy outdoors and many school sports events.',
    workHours: 'Mon-Fri, Hours vary',
  },
  {
    name: 'Ashley Potter (Owner)',
    title: 'BS, MS, PA-C',
    picture: ashley,
    description: "I am a Physician Associate that has been working in New Zealand since early 2020. I hold a Bachelor's degree in Physiology and a Masters degree in Health Science, Physician Assistant Studies. I moved to New Zealand with my family from the United States where I had been practicing in General Practice since 2008. I enjoy developing a relationship with my patients and helping them live a healthy life. I enjoy exploring the outdoors with my family and friends. Surfing, running, and tramping are my passions. My family and I enjoy living in the beautiful Aotearoa.",
    workHours: 'Mon, Thu, Fri, Hours vary',
  },
  {
    name: 'Dr Juliann Kury',
    title: 'BA, MD',
    picture: juliann,
    description: 'Originally from the US, I am a Family Doctor who moved from North Carolina to New Zealand in late 2020. I first worked in Urgent Care at White Cross in Auckland and then moved to Cambridge to work as a GP for a more rural life for my family in Jan 2022. I received a Bachelor’s of Arts in Middle Eastern Studies from Brown University in 2003. I then spent 18 months teaching English in Japan before returning to medical school at the Warren Alpert School of Medicine at Brown University. I completed my Family Practice training in Seattle and became US Board Certified in 2011. Since then I have worked both GP with women’s health and also Urgent Care in various parts of the US and now New Zealand. I have a special interest in Women’s Health (with IUDs), minor procedures, Paediatrics and Travel Medicine. When not at work, my husband and I are kept busy by our three small children and dog. We like to explore new places and spend time in the outdoors. Since our move to Cambridge we have settled in well and consider Aotearoa our home.',
    workHours: 'Mon, Tue, Wed, Fri, Hours vary',
  },
  {
    name: 'Dr Jacqueline Greig',
    title: 'MBChB (Hons), PGDipOMG, FrNZCUC',
    picture: jacqueline,
    description: 'Jacqui is an urgent care fellow.',
    workHours: 'Mon, Wed, Hours vary',
  },
  {
    name: 'Claudia Barclay',
    title: 'Nurse Prescriber, RN',
    picture: claudia,
    description: 'My nursing training started 25 years ago at the University Of Heidelberg, Germany and is still ongoing. During this time I have gained experience in orthopaedics, general medicine, research, emergency and urgent care as well as stroke care. I have now settled in general practice as a nurse prescriber, where I rediscovered how much I enjoy working with children and supporting patients through long term conditions as well as still being able to follow my passion of emergency care.',
    workHours: 'Mon-Fri, Hours vary',
  },
  {
    name: 'Angela Rickit',
    title: 'Nurse Prescriber, BNurs, PGdip',
    picture: angela,
    workHours: 'Mon-Wed, Hours vary',
  },
  {
    name: 'Dr Kar Wai Kuet',
    title: 'BSc(Hons), MBChb, MRCP (UK), DFRSH(UK), MRCGP(UK)',
    workHours: 'Mon-Thu, Hours vary',
  },
];

function Team() {
  return (
    <div className="component-team">
      <h2>
        <span>Meet Our</span>
        <span className="highlight-color">Team</span>
      </h2>
      <div className="doctors-container">
        {TEAM.map((doctor) => (
          <div key={doctor.name} className="doctor">
            {doctor.picture ? (<img src={doctor.picture} alt={doctor.name} className="doctor-picture" />) : (<div className="doctor-picture" />)}
            <div className="doctor-info">
              <strong className="doctor-name">{doctor.name}</strong>
              <p className="doctor-title">{doctor.title}</p>
              <p className="doctor-hours">{doctor.workHours}</p>
              {doctor.description && (<p className="doctor-description">{doctor.description}</p>)}
              {doctor.description && (<div className="description-fade" />)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
